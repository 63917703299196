
@media (max-width: 1075px) {
    .circle-btn-product {
        width: 50%;
        height: 3vw;
        border-radius: 50%;
        border: none;
        outline: none; /* Remove the default button outline */
        cursor: pointer;
    }
}
  
@media (min-width: 1076px) {
    .circle-btn-product {
        width: 50%;
        height: 1vw;
        border-radius: 50%;
        border: none;
        outline: none; /* Remove the default button outline */
        cursor: pointer;
    }
}

.padding-right{
    padding-right: 3px;
}

.backgroud-color
{
    background-color: #dddddd;
}

.specsimage-height
{
    height: 148.27px;
}