.button-gap
{
    padding-left: 10px;
    padding-right: 10px;
}

.transparent-button
{
  background-color: transparent;
  border: none;
}

.transparent-button:hover
{
    height: 30px;
}

.hover-pointer {
  cursor: pointer;
}