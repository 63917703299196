.background-color
{
    background-color: whitesmoke;
}

.detail-image-size{
    width: 100%;
}

.col-border{
    border: 1px solid black;
}

.row-padding{
    padding-top: 50px;
}

.row-padding-colour{
    padding-top: 20px;
}

.product-name-margin
{
    margin-bottom: 5px;
}

.product-price-margin
{
    margin-bottom: 20px;
}

.product-info-margin
{
    margin-bottom: 10px;
}

.product-info-margin-top
{
    margin-top: 4px;
}

.no-marigin-top
{
    margin-top: 0px;
}

.row-top-border
{
    border-top: 1px solid lightblue;
}

@media (max-width: 1075px) {
    .circle-btn {
        width: 50%;
        height: 4vw;
        border-radius: 50%;
        border: none;
        outline: none; /* Remove the default button outline */
        cursor: pointer;
    }
}
  
@media (min-width: 1076px) {
    .circle-btn {
        width: 50%;
        height: 1vw;
        border-radius: 50%;
        border: none;
        outline: none; /* Remove the default button outline */
        cursor: pointer;
    }
}