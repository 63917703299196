.header-padding
{
  padding-top: 15px;
}

.header-button-height
{
    height: 40px;
}

.image-size
{
    height:100px;
    width: auto;
}