.footer-background
{
    margin: 0; /* Remove default margin */
    padding: 0; /* Remove default padding */
    background-image: url('../../images/background1.jpg'); /* Replace with the path to your image */
    background-size: cover; /* Cover the entire background */
    background-position: center; /* Center the background image */
    background-repeat: no-repeat; /* Do not repeat the background image */
}

/* height: 25vh; */

@media (max-width: 1024px) {
    .footer-height{
        height: 20vh;
    }
    
}
  
@media (min-width: 1025px) {
    .footer-height{
        height: 30vh;
    }
}

footer {
    position: static;
    width: 100%;
    bottom: 0;
    padding-top: 15px;
}