@media (max-width: 500px) {
    .overlay {
        width: 90%;
        position: absolute;
        top: 4%;
        left: 0;
        z-index: 0; /* Place the overlay behind the content */
    }
    
}
  
@media (min-width: 501px) {
    .overlay {
        width: 90%;
        position: absolute;
        top: 7%;
        left: 0;
        z-index: 0; /* Place the overlay behind the content */
    }
}

.paddingright{
    padding-right: 15px;
}

.padding-top
{
    padding-top: 15px;
}

.button-gap1
{
    padding-left: 10px;
    padding-right: 10px;
}

.transparent-button1
{
  background-color: #6871b6;
  border-radius: 10px;
  color: white;
  border: none;
  padding-block: 3px;
}
