.image-height
{
    height: 100%;
    width: 100%;
    -webkit-background-size: cover!important;
    -moz-background-size: cover!important;
    background-size: cover!important;
    background-position: center center!important;
    background-repeat: no-repeat!important;
}


.overlay {
    width: 97%;
    position: absolute;
    top: 10%;
    left: 0;
    z-index: 0; /* Place the overlay behind the content */
}

.padding-right{
    padding-right: 15px;
}

.padding-top
{
    padding-top: 25px;
}

.button-gap1
{
    padding-left: 10px;
    padding-right: 10px;
}

.transparent-button1
{
  background-color: #6871b6;
  border-radius: 10px;
  color: white;
  border: none;

}

.border-width
{
    width: 50%;
}

.image-size-testimony
{
    width: 100%;
    height: auto;
}

.hover-pointer {
    cursor: pointer;
  }