.box-colour
{
  background-color: transparent;
}

.first-font
{
  font-family:'Gabarito';
}

.second-font
{
  font-family: 'Caveat', cursive;
}

.third-font
{
  font-family: 'Smooch Sans', sans-serif;
}

.font-color
{
  color: white;
}

.font-default-color
{
  color: #3b61ab;
}

.font-secondary-color
{
  color: #a49c9d;
}

.direct-allign-bottom-center
{
    display: flex;
    align-items:end;
    justify-content: center;
}

.direct-allign-center
{
    display: flex;
    align-items:center;
    justify-content: center;
}

.direct-align-top-right
{
  display: flex;
  align-items:start;
  justify-content: end;
}

.direct-align-top-center
{
  display: flex;
  align-items:start;
  justify-content: center;
}

.display-center-gap
{
  display: flex;
  justify-content: space-between;
}

.display-image-center-gap
{
  display: inline-flex;
  justify-content: space-between;
}

.direct-allign-center-left
{
    display: flex;
    align-items:center;
    justify-content: left;
}

.border-bottom
{
  border-bottom: 1px solid black;
}

.text-align-right
{
  text-align: right;
}

@media (max-width: 1024px) {
  :where(.css-dev-only-do-not-override-2i2tap).ant-card .ant-card-meta-title
  {
      font-size: 3.5vw;
  }

  .font-size-xsmall
  {
    font-size: 3.5vw;
  }

  .font-size-small
  {
    font-size: 4vw;
  }

  .font-size-medium
  {
    font-size: 5vw;
  }

  .font-size-big
  {
    font-size: 6vw;
  }
}

@media (min-width: 1025px) {
  :where(.css-dev-only-do-not-override-2i2tap).ant-card .ant-card-meta-title
  {
      font-size: 1.2vw;
  }

  .font-size-xsmall
  {
    font-size: 1.5vw;
  }

  .font-size-small
  {
    font-size: 2vw;
  }

  .font-size-medium
  {
    font-size: 2.5vw;
  }

  .font-size-big
  {
    font-size: 3vw;
  }
}